<template>
  <div>
    <div class="d-flex flex-column flex-root justify-content-center align-items-center" >
      <h1>Vui lòng đợi</h1>
    </div>
    <div id="LoadingPanel" v-show="loadingPanel">
      <div class="text-center">
        <div class="spinner-border" role="status"></div>
      </div>
    </div>
  </div>

</template>
<script>

import { mapGetters, mapState } from "vuex";
import {
  // LOGIN_GOOGLE,
  LOGIN,
  // LOGOUT,
  // SET_ERROR
} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
export default {
  name: "login-goole",
  data() {
    return {
      loadingPanel: false,
      state: "signin",
      params: null,
    };
  },
  created() {
    this.state = "signin";
    this.login();
  },
  components: {},
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),
  },
  mounted() {

  },
  methods: {

    login() {
      this.showLoading(true);
      this.params = this.$route.query;

      if (!this.params) {
        this.$router.push({name: "login"});
        this.showLoading(false)
        return;
      }

      let credential = this.params.credential;
      this.$store.dispatch(LOGIN, {credential: credential}).then(() => {
        if (this.errors) {
          Swal.fire({
            title: "",
            text: this.errors,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
        this.$router.push({name: "dashboard"});
        this.showLoading(false)
      });
    },
    showLoading(data) {

      if (!data) {
        setTimeout(() => {
          this.loadingPanel = data;
        }, 500);
        return;
      }
      this.loadingPanel = data;

    },



    // loginGoogle() {
    //   this.$gAuth
    //     .signIn()
    //     .then((GoogleUser) => {
    //       // on success do something
    //       var access_token = GoogleUser.getAuthResponse().access_token;
    //       if (!access_token) {
    //         this.notifyAlert("warn", "Lỗi không tìm thấy access token google");
    //         return;
    //       }
    //       this.$bus.$emit("show-loading", true);
    //       // clear existing errors
    //       this.$store.dispatch(LOGIN, { access_token }).then(() => {
    //         if (this.errors) {
    //           Swal.fire({
    //             title: "",
    //             text: this.errors,
    //             icon: "error",
    //             confirmButtonClass: "btn btn-secondary",
    //             heightAuto: false,
    //           });
    //         }
    //         this.$router.push({ name: "dashboard" });
    //         this.$bus.$emit("show-loading", false);
    //       });
    //     })
    //     .catch((error) => {
    //       alert(error);
    //     });
    // },
  },
};
</script>
